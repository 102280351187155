import React from 'react'

const Products = () => {
    return (
        <div>
            Soy el listador de productos
        </div>
    )
}

export default Products